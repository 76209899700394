<template>
  <div class="image-container">
    <img class="galleryViewImage" crossorigin="anonymous" v-if="imageUrl" :src="imageUrl" alt="Fetched Image" />

    <div v-else-if="loading" class="skeleton-box"></div>

    <div v-else class="error-box">
      <p>Error fetching image</p>
      <button @click="fetchImageUrl" class="retry-button">Retry</button>
    </div>
  </div>
</template>

<script>
import { getAttachmentURL } from "@/services/service";

export default {
  name: "ImageFetcher",
  props: {
    fileName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      imageUrl: "",
      loading: true,
      error: false,
    };
  },
  async mounted() {
    await this.fetchImageUrl();
  },
  watch: {
    fileName: {
      immediate: false, 
      handler() {
        this.fetchImageUrl();
      },
    },
  },
  methods: {
    async fetchImageUrl() {
      this.loading = true;
      this.error = false;

      try {
        const url = await getAttachmentURL(this.fileName);
        if (url) {
          this.imageUrl = `${url}?download=1`;
        } else {
          throw new Error("Invalid response");
        }
      } catch (error) {
        console.error("Error fetching image URL:", error);
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  border-radius: 9px;
  overflow: hidden;
  position: relative;
}

.galleryViewImage {
  border-radius: 9px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.skeleton-box {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #ddd 25%, #e0e0e0 50%, #ddd 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 9px;
}

@keyframes shimmer {
  0% { background-position: -200% 0; }
  100% { background-position: 200% 0; }
}

.error-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffcccc;
  color: #b00020;
  font-weight: bold;
  border-radius: 9px;
  text-align: center;
  padding: 10px;
}

.retry-button {
  margin-top: 8px;
  padding: 6px 12px;
  background: #b00020;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  width: max-content;
  height: max-content;
}

.retry-button:hover {
  background: #90001a;
}
</style>
